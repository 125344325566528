import { useCallback, useEffect, useRef } from 'react'
import { useExperiment } from '@deal/experiment-js'
import useBusinessUser from './useBusinessUser'
import { ExpertPresenceStatus } from '#src/generated/types'
import { isMobileNativeOrBrowser } from '../services/mobile'
import { useUserAgentContext } from '../context/UserAgent'
import { useActionThrottle } from './useActionThrottle'
import { useSetOnlineNudgeContext } from '../context/SetOnlineNudge'

const ELIGIBLE_STATUSES: ExpertPresenceStatus[] = [ExpertPresenceStatus.OFF_SHIFT]
const ONE_HOUR_IN_MILLIS = 1000 * 60 * 60

export type UseShouldFireSetOnlineNudgeState = {
  shouldFireSetOnlineNudge: boolean
  schedulePotentialSetOnlineNudge: (timeToWaitMillis: number) => void
}

export const useSetOnlineNudge = (): UseShouldFireSetOnlineNudgeState => {
  const userAgent = useUserAgentContext()
  const { setShowSetOnlineNudge } = useSetOnlineNudgeContext()
  const isMobile = isMobileNativeOrBrowser(userAgent)
  const { result } = useExperiment({
    experiment: 'biz-app-expert-availability-nudges',
    defaultTreatment: 'control'
  })
  const {
    expertPresence: { status }
  } = useBusinessUser()
  const { isAllowedToRun, markAsRun } = useActionThrottle(
    'expert-flex-mode-nudge',
    ONE_HOUR_IN_MILLIS
  )

  const shouldFireSetOnlineNudge =
    result !== 'control' && !isMobile && ELIGIBLE_STATUSES.includes(status) && isAllowedToRun

  const timeout = useRef<NodeJS.Timeout>()
  useEffect(() => {
    return () => clearTimeout(timeout.current)
  }, [])

  // Clear the timeout if the nudge should no longer be fired
  useEffect(() => {
    if (!shouldFireSetOnlineNudge) {
      clearTimeout(timeout.current)
      timeout.current = undefined
    }
  }, [shouldFireSetOnlineNudge])

  const schedulePotentialSetOnlineNudge = useCallback(
    (timeToWaitMillis: number) => {
      // If we're already waiting for a timeout, don't schedule another one
      if (!timeout.current) {
        timeout.current = setTimeout(() => {
          if (shouldFireSetOnlineNudge && window) {
            setShowSetOnlineNudge(true)
            markAsRun()
          }
          timeout.current = undefined
        }, timeToWaitMillis)
      }
    },
    [shouldFireSetOnlineNudge, markAsRun]
  )

  return { shouldFireSetOnlineNudge, schedulePotentialSetOnlineNudge }
}
