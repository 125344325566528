import 'react'
import { useCallback } from 'react'
import useLocalStorageState from 'use-local-storage-state'

export type UseActionThrottleState = {
  isAllowedToRun: boolean
  markAsRun(): void
}

export function useActionThrottle(key: string, timeToWaitMillis: number): UseActionThrottleState {
  const [runAgainAfter, setRunAgainAfter] = useLocalStorageState<number>(`${key}_expiration`)
  const isAllowedToRun = !runAgainAfter || new Date().getTime() > runAgainAfter

  const markAsRun = useCallback(() => {
    const now = new Date()
    const expirationTime = now.getTime() + timeToWaitMillis
    setRunAgainAfter(expirationTime)
  }, [timeToWaitMillis, setRunAgainAfter])

  return {
    isAllowedToRun,
    markAsRun
  }
}

export default useActionThrottle
