import React, { useState } from 'react'
import useBusinessUser from '#src/app/hooks/useBusinessUser'
import { toast } from 'react-toastify'
import { useStartExpertStandbyMutation } from '../../startExpertStandby.generated'
import StartStandbyModal from '#src/app/components/StartStandbyModal'
import { Button } from '@deal/components'
import { ExpertPresenceStatus } from '#src/generated/types'
import styles from './styles.css'

const StartStandbyButton: React.FC = () => {
  const businessUser = useBusinessUser()
  const [showStandbyModal, setShowStandbyModal] = useState(false)
  const [startExpertStandby] = useStartExpertStandbyMutation({
    onCompleted: data => {
      if (data.startExpertStandby.error) {
        toast.error(data.startExpertStandby.error)
      } else if (
        data.startExpertStandby.businessUser &&
        data.startExpertStandby.businessUser.expertPresence.status ===
          ExpertPresenceStatus.ON_STANDBY
      ) {
        setShowStandbyModal(false)
        toast.success('Expert flex mode started')
      }
    }
  })

  return (
    <>
      <Button size="small" className={styles.button} onClick={() => setShowStandbyModal(true)}>
        Start Flex
      </Button>
      {showStandbyModal && (
        <StartStandbyModal
          businessUser={businessUser}
          onRequestClose={() => setShowStandbyModal(false)}
          onStartStandby={(duration, acceptingNewLeads) =>
            startExpertStandby({
              variables: {
                id: businessUser.id,
                duration,
                acceptingNewLeads
              }
            })
          }
        />
      )}
    </>
  )
}

export default StartStandbyButton
