import React, { useContext, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import subMinutes from 'date-fns/subMinutes'
import isBefore from 'date-fns/isBefore'
import { useExperiment } from '@deal/experiment-js'
import { ExpertPresenceStatus } from '#src/generated/types'
import useBusinessUser from '#src/app/hooks/useBusinessUser'
import { GlobalView, GlobalViewsContext } from '#src/app/context/GlobalViews'
import ManageAwayAutoReply from '#src/app/components/ManageAwayAutoReply'
import EndOfShiftSurveyPathModal from '#src/app/components/EndOfShiftSurveyPathModal'
import { useStartExpertStandbyMutation } from '../startExpertStandby.generated'
import { useStartExpertShiftMutation } from '../startExpertShift.generated'
import { useSetExpertOffShiftMutation } from '../setExpertOffShift.generated'
import StartStandbyModal, { showStartStandbyButton } from '#src/app/components/StartStandbyModal'
import MenuSection from '../MenuSection'
import MenuRow from '../MenuRow'
import EndShiftConfirmation from '../EndShiftConfirmation'
import PayPerLeadDailySummaryModal from '../../PayPerLeadDailySummary/PayPerLeadDailySummaryModal'
import WakeupSnooozedLeadsModal from '../WakeupSnoozedLeadsModal'

const updateAppcuesIdentify = (businessUserId: string, presenceStatus: ExpertPresenceStatus) => {
  window.Appcues?.identify(businessUserId, {
    expertPresenceStatus: presenceStatus
  })
}

const SelectExpertStatus: React.FC = () => {
  const previousStatus = useRef<undefined | ExpertPresenceStatus>(undefined)
  const [showAwayModal, setShowAwayModal] = useState(false)
  const [showExtendedAwayModal, setShowExtendedAwayModal] = useState(false)
  const [showOffShiftConfirmation, setShowOffShiftConfirmation] = useState(false)
  const [showStandbyModal, setShowStandbyModal] = useState(false)
  const [showSurveyModal, setShowSurveyModal] = useState(false)
  const [showWakeUpSnoozedLeadsModal, setShowWakeUpSnoozedLeadsModal] = useState(false)
  const [showDailySummaryModal, setShowDailySummaryModal] = useState(false)
  const [shiftId, setShiftId] = useState()
  const { openView } = useContext(GlobalViewsContext)

  const businessUser = useBusinessUser()

  // we are not using the information, so don't show it for now.
  // keep the code here in case we want to start ing it again
  const showShiftDifficultyModal = false

  const status = businessUser.expertPresence.status

  const { result } = useExperiment({
    experiment: 'biz-app-extended-away',
    defaultTreatment: 'control'
  })
  const { result: resultAvailabilityNudges } = useExperiment({
    experiment: 'biz-app-expert-availability-nudges',
    defaultTreatment: 'control'
  })

  const showOffShift = status === ExpertPresenceStatus.ON_STANDBY

  const [startExpertShift] = useStartExpertShiftMutation({
    variables: {
      id: businessUser.id
    },
    onCompleted: data => {
      if (data.startExpertShift.error) {
        toast.error(data.startExpertShift.error)
      } else if (
        data.startExpertShift.businessUser &&
        data.startExpertShift.businessUser.expertPresence.status === ExpertPresenceStatus.ON_SHIFT
      ) {
        updateAppcuesIdentify(businessUser.id, ExpertPresenceStatus.ON_SHIFT)
        toast.success('CAT started')
      }
    }
  })

  const [startExpertStandby] = useStartExpertStandbyMutation({
    onCompleted: data => {
      if (data.startExpertStandby.error) {
        toast.error(data.startExpertStandby.error)
      } else if (
        data.startExpertStandby.businessUser &&
        data.startExpertStandby.businessUser.expertPresence.status ===
          ExpertPresenceStatus.ON_STANDBY
      ) {
        resultAvailabilityNudges !== 'control' && setShowWakeUpSnoozedLeadsModal(true)
        setShowStandbyModal(false)
        updateAppcuesIdentify(businessUser.id, ExpertPresenceStatus.ON_STANDBY)
        toast.success('Expert flex mode started')
      }
    }
  })

  const [setExpertOffShift] = useSetExpertOffShiftMutation({
    variables: {
      id: businessUser.id
    },
    onCompleted: data => {
      if (data.setExpertOffShift.error) {
        toast.error(data.setExpertOffShift.error)
      } else if (
        data.setExpertOffShift.businessUser &&
        data.setExpertOffShift.businessUser.expertPresence.status === ExpertPresenceStatus.OFF_SHIFT
      ) {
        if (data.setExpertOffShift.shouldShowEndOfShiftSurvey) {
          setShowSurveyModal(true)
        }

        if (previousStatus.current && previousStatus.current == ExpertPresenceStatus.ON_SHIFT) {
          setShowOffShiftConfirmation(false)
          if (showShiftDifficultyModal) {
            openView(GlobalView.ShiftDifficultyModal)
          }
        }
        updateAppcuesIdentify(businessUser.id, ExpertPresenceStatus.OFF_SHIFT)
      }
    }
  })

  const setExpertOffShiftAndSaveShift = () => {
    // Persist the expert's current shiftId before ending so that we can pass it to the end of shift survey path
    if (
      businessUser.expertPresence.__typename === 'ExpertPresenceOnShift' ||
      businessUser.expertPresence.__typename === 'ExpertPresenceOnCall'
    ) {
      setShiftId(businessUser.expertPresence.shiftId)
    }
    setExpertOffShift()
  }

  const isActiveExpert = businessUser.active
  return (
    <>
      <MenuSection title="START SHIFT">
        <MenuRow
          onClick={() => {
            if (status !== ExpertPresenceStatus.ON_SHIFT) {
              previousStatus.current = status
              startExpertShift()
            }
          }}
          title="Customer Acquisition Time (CAT)"
          subTitle="Click here to start CAT"
          checked={status === ExpertPresenceStatus.ON_SHIFT}
        />
        {showStartStandbyButton(businessUser, resultAvailabilityNudges!) && (
          <MenuRow
            onClick={() => {
              previousStatus.current = status
              if (status !== ExpertPresenceStatus.ON_STANDBY) {
                setShowStandbyModal(true)
              }
            }}
            title="Flex Mode"
            subTitle="For when no CAT available"
            checked={status === ExpertPresenceStatus.ON_STANDBY}
          />
        )}
        {showOffShift && (
          <MenuRow
            onClick={() => {
              previousStatus.current = status
              if (
                businessUser.expertPresence.__typename === 'ExpertPresenceOnShift' &&
                isBefore(new Date(), subMinutes(new Date(businessUser.expertPresence.endsAt), 15))
              ) {
                setShowOffShiftConfirmation(true)
              } else {
                setExpertOffShiftAndSaveShift()
              }
            }}
            title="Off CAT"
            subTitle="Stop CAT"
            checked={false}
          />
        )}
      </MenuSection>
      <MenuSection title="SET AWAY STATUS">
        {isActiveExpert && (
          <MenuRow
            onClick={() => {
              setShowAwayModal(true)
            }}
            title="Away"
            subTitle="Automatically send an away message (Up to 1 month)"
            checked={status === ExpertPresenceStatus.AWAY}
          />
        )}
        {((isActiveExpert && result === 'on') || status === ExpertPresenceStatus.EXTENDED_AWAY) && (
          <MenuRow
            onClick={() => {
              setShowExtendedAwayModal(true)
            }}
            title="Extended Away"
            subTitle="Automatically send an away message (1 - 6 months)"
            checked={status === ExpertPresenceStatus.EXTENDED_AWAY}
            lockMessage={
              businessUser.isEligibleForExtendedAway ||
              businessUser.expertPresence.status === ExpertPresenceStatus.EXTENDED_AWAY
                ? undefined
                : 'Only Plat+ Experts with 50+ sales are eligible'
            }
          />
        )}
      </MenuSection>
      {showStandbyModal && (
        <StartStandbyModal
          businessUser={businessUser}
          onRequestClose={() => setShowStandbyModal(false)}
          onStartStandby={(duration, acceptingNewLeads) =>
            startExpertStandby({
              variables: {
                id: businessUser.id,
                duration,
                acceptingNewLeads
              }
            })
          }
        />
      )}
      {(showAwayModal || showExtendedAwayModal) && (
        <ManageAwayAutoReply
          expertId={businessUser.id}
          onRequestClose={() => {
            setShowAwayModal(false)
            setShowExtendedAwayModal(false)
          }}
          isForExtendedAway={showExtendedAwayModal}
        />
      )}
      {showOffShiftConfirmation && (
        <EndShiftConfirmation
          onEndShiftConfirmed={setExpertOffShiftAndSaveShift}
          onRequestClose={() => setShowOffShiftConfirmation(false)}
        />
      )}
      {showDailySummaryModal && (
        <PayPerLeadDailySummaryModal onRequestClose={() => setShowDailySummaryModal(false)} />
      )}
      {showWakeUpSnoozedLeadsModal && (
        <WakeupSnooozedLeadsModal onRequestClose={() => setShowWakeUpSnoozedLeadsModal(false)} />
      )}
      <EndOfShiftSurveyPathModal
        onCloseModal={() => setShowSurveyModal(false)}
        isOpen={showSurveyModal && !showDailySummaryModal}
        shiftId={shiftId}
      />
    </>
  )
}

export default SelectExpertStatus
