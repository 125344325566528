import React, { useContext, useState } from 'react'

type SetOnlineNudgeContextType = {
  showSetOnlineNudge: boolean
  setShowSetOnlineNudge: React.Dispatch<React.SetStateAction<boolean>>
}

const SetOnlineNudgeContext = React.createContext<SetOnlineNudgeContextType>({
  showSetOnlineNudge: false,
  setShowSetOnlineNudge: () => {}
})

const SetOnlineNudgeConsumer = SetOnlineNudgeContext.Consumer

const SetOnlineNudgeProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const [showSetOnlineNudge, setShowSetOnlineNudge] = useState(false)

  return (
    <SetOnlineNudgeContext.Provider
      value={{
        showSetOnlineNudge,
        setShowSetOnlineNudge
      }}
    >
      {children}
    </SetOnlineNudgeContext.Provider>
  )
}

const useSetOnlineNudgeContext = () => {
  const showSetOnlineNudgeContext = useContext(SetOnlineNudgeContext)

  if (!showSetOnlineNudgeContext) {
    throw new Error('Cannot access SetOnlineNudgeContext outside the provider')
  }

  return showSetOnlineNudgeContext
}

export {
  SetOnlineNudgeConsumer,
  SetOnlineNudgeProvider,
  SetOnlineNudgeContext,
  useSetOnlineNudgeContext
}
