import React, { useContext } from 'react'
import { Popover, Tooltip } from '@deal/components'
import { Permission, useHasPermission } from '#src/app/utilities/permission'
import useBusinessUser from '#src/app/hooks/useBusinessUser'
import { IdentityContext } from '#src/app/containers/Identity'
import { useQuickReplyContext } from '#src/app/containers/ExpertChat/QuickReplyContext'
import UserMenu from '../UserMenu'
import Status from '../Status'
import ExpertPayInformation from '../ExpertPayInformation'
import ChevronDown from './chevron-down.svg'
import styles from './styles.css'
import MarketHeatIndicator from '../MarketHeatIndicator'
import { useSetOnlineNudgeContext } from '#src/app/context/SetOnlineNudge'
import SetOnlineNudgeToast from '../../SetOnlineNudgeToast'

interface NavigationHeaderProps {}

const NavigationHeader: React.FC<React.PropsWithChildren<NavigationHeaderProps>> = () => {
  const businessUser = useBusinessUser()
  const { myself } = useContext(IdentityContext)
  const { showQuickReplyUserMenuTooltip } = useQuickReplyContext()
  const { showSetOnlineNudge, setShowSetOnlineNudge } = useSetOnlineNudgeContext()

  if (!myself) return null

  const displayName = businessUser.displayName
  const hasMultipleAccounts =
    myself.businessUser?.user.assignedBusinessUsers &&
    myself.businessUser.user.assignedBusinessUsers.length > 1

  /**
   * Using LEAD_READ permission as a proxy for if this expert should be able to interact with any shift/status related features.
   * Expert presence mutations are all gated by a generic BUSINESS_USER_UPDATE permission which is too broad.
   */
  const [canEngageWithLeads] = useHasPermission(Permission.LEAD_READ)

  return (
    <section className={styles.container}>
      <Popover
        placement="bottom-start"
        interactive
        hideArrow
        content={<UserMenu />}
        contentLabel="User menu"
      >
        <div className={styles.name}>
          <Tooltip
            visible={showQuickReplyUserMenuTooltip}
            message="You can always manage your quick replies here"
            placement="right"
            hideTooltipOnMobile
          >
            <Popover
              contentLabel="Set online nudge"
              visible={showSetOnlineNudge}
              content={<SetOnlineNudgeToast onRequestClose={() => setShowSetOnlineNudge(false)} />}
              placement="right"
            >
              {displayName}
              &nbsp;
              <ChevronDown className={styles.arrowIcon} />
            </Popover>
          </Tooltip>
        </div>
      </Popover>
      <div className={styles.lowerContainer}>
        {hasMultipleAccounts && (
          <div className={styles.department}>
            <div>{businessUser.department.displayName}</div>
          </div>
        )}
        {canEngageWithLeads && (
          <>
            <ExpertPayInformation />
            <Status />
            <MarketHeatIndicator />
          </>
        )}
      </div>
    </section>
  )
}

export default NavigationHeader
