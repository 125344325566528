import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { Button, Heading, Text } from '@deal/bluxome'
import useBusinessUser from '#src/app/hooks/useBusinessUser'
import { useStartExpertStandbyMutation } from '../Navigation/startExpertStandby.generated'
import StartStandbyModal from '../StartStandbyModal'
import { ExpertPresenceStatus } from '#src/generated/types'
import styles from './styles.css'

interface SetOnlineNudgeToastProps {
  onRequestClose?: () => void
}

const SetOnlineNudgeToast: React.FC<SetOnlineNudgeToastProps> = ({ onRequestClose }) => {
  const businessUser = useBusinessUser()
  const [showStandbyModal, setShowStandbyModal] = useState(false)

  const [startExpertStandby] = useStartExpertStandbyMutation({
    onCompleted: data => {
      if (data.startExpertStandby.error) {
        toast.error(data.startExpertStandby.error)
      } else if (
        data.startExpertStandby.businessUser &&
        data.startExpertStandby.businessUser.expertPresence.status ===
          ExpertPresenceStatus.ON_STANDBY
      ) {
        setShowStandbyModal(false)
        toast.success('Flex mode started')
        onRequestClose?.()
      }
    }
  })

  return (
    <div className={styles.container}>
      <button className={styles.closeButton} onClick={onRequestClose}>
        x
      </button>
      <Heading size="h4-loose">Available to chat? 💬</Heading>
      <Heading size="h4-loose">Go on Flex to let your leads know you're online!</Heading>
      <Text>
        {businessUser.canAcceptLeadsInStandby
          ? 'Maximize your earnings by receiving new leads and prioritized follow-up opportunities 💰'
          : 'Maximize your earnings by receiving prioritized follow-up and re-engagement opportunities.'}
      </Text>

      <Button color="learn-blue" size="small" onPress={() => setShowStandbyModal(true)}>
        Start Flex
      </Button>
      {showStandbyModal && (
        <StartStandbyModal
          businessUser={businessUser}
          onRequestClose={() => setShowStandbyModal(false)}
          onStartStandby={(duration, acceptingNewLeads) =>
            startExpertStandby({
              variables: {
                id: businessUser.id,
                duration,
                acceptingNewLeads
              }
            })
          }
        />
      )}
    </div>
  )
}

export default SetOnlineNudgeToast
